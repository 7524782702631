import React, { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
const About = React.lazy(() => import('./about'))
// const About = React.lazy(() => import('./about') .catch((e) => window.location.reload()))

export default function App() {
  return (
    <ErrorBoundary>
      <Router>
        <div>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
            </ul>
          </nav>

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/about">
              <Suspense fallback={<div>Loading...</div>}>
                <About />
              </Suspense>
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    </ErrorBoundary>
  )
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    console.log({ error })
    if (error.name === 'ChunkLoadError') {
      // TODO: prevent infinite loop
      alert('reloading the page')
      window.location.reload()
    }
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return <p>Loading failed! Please reload.</p>
    }

    return this.props.children
  }
}

function Home() {
  return <h2>Home</h2>
}
